// @flow

import type {PropertyPath} from './message.type'

const base = require('./core')

module.exports.read = function (path: PropertyPath, callback?: Function) {
    if (callback !== undefined) {
        base.emitter.once(path, callback)
    }
    base.sendMessage({
        action: 'READ',
        path: path
    })
}

module.exports.write = function (path: PropertyPath, value: any, callback?: Function) {
    if (callback !== undefined) {
        base.emitter.once(path, callback)
    }
    base.sendMessage({
        action: 'WRITE',
        path: path,
        payload: value
    })
}

module.exports.subscribe = function (path: PropertyPath, callback: Function) {
    if (base.getAuthentication() !== null) {
        console.log('attempt to subscribe a listener without initializing the COBI.js library')
    }
    base.emitter.addListener(path, callback)
}

module.exports.unsubscribe = function (path: PropertyPath, callback?: Function) {
    if (callback !== undefined) {
        base.emitter.removeListener(path, callback)
    } else {
        base.emitter.removeAllListeners(path)
    }
}
