// @flow

import type {Message} from './message.type'

const EventEmitter = require('events')
const emitter = new EventEmitter()
let authentication = null // internal state

function context () {
    if (global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cobiAuth &&
        global.webkit.messageHandlers.cobiAuth.postMessage) {
        return 'ios'
    }

    if (global.cobijsAndroidImpl && global.cobijsAndroidImpl.messageFromJs) {
        return 'android'
    }

    if (typeof process === 'object' && process.toString() === '[object process]') {
        return 'node'
    }
}

module.exports.emitter = emitter // exposed for backwards compatibility
module.exports.getAuthentication = () => authentication

module.exports.init = function (apiKey: string, specVersion: string) {
    const currentContext = context()

    // if runtime reached this point it is most likely that we are
    // in a desktop browser (simulator). Therefore we just timeout the
    // request until the simulator kicks in and monkey patches
    // the webkit code
    if (currentContext != null) {
        emitter.emit('auth', { token: apiKey, version: specVersion })
    } else {
        const wait = 500 // milliseconds
        console.log(`COBI.init failed. Retrying in ${wait} milliseconds`)
        setTimeout(() => module.exports.init(apiKey, specVersion), wait)
    }
}

module.exports.receiveAuthentication = function (result: {confirmed: boolean, apiKey: string}) {
    if (result && result.confirmed && result.apiKey) {
        console.log('COBI.js authenticated')
        authentication = result
    } else {
        console.error(`Invalid COBI.js authentication: ${JSON.stringify(result)}`)
    }
}

module.exports.receiveMessage = function (msg: Message) {
    // for the time being we just wrap the emit function
    // in the future we might request the COBI.js lib
    // to respond to read/write messages
    const timestamp = new Date(msg.timestamp || Date.now())
    emitter.emit(msg.path, msg.payload, timestamp)
}

module.exports.sendMessage = function (msg: Message) {
    const wait = 500 // milliseconds
    const currentContext = context()
    if (authentication && authentication.apiKey === null) {
        // the user has not authenticated, retry in a while hoping that he does in that time
        console.log(`COBI.js message couldn't not be sent: missing authentication key. Retrying in ${wait} milliseconds`)
        return setTimeout(() => module.exports.sendMessage(msg), wait)
    }

    if (currentContext != null) {
        emitter.emit('shell', msg)
    } else {
        console.log(`COBI.js could not send your message. Retrying in ${wait} milliseconds`)
        return setTimeout(() => module.exports.sendMessage(msg), wait) // ms
    }
}

function urlParameterRegex (name: string) {
    return new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')
}
// Helper function to fetch the value of a specific URL parameter with
// a provided name
module.exports.getUrlParameter = function (name: string) {
    const regex = urlParameterRegex(name)
    const result = regex.exec(global.location.search)
    if (result !== null) {
        return result[1].replace(/\+/g, '%20')
    }
    return null
}

emitter.on('auth', message => {
    // iOS hooks
    if (global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cobiAuth &&
        global.webkit.messageHandlers.cobiAuth.postMessage) {
        return global.webkit.messageHandlers.cobiAuth.postMessage(message)
    }

    // Android hooks
    if (global.cobijsAndroidImpl && global.cobijsAndroidImpl.messageFromJs) {
        return global.cobijsAndroidImpl.messageFromJs('cobiAuth', JSON.stringify(message))
    }
})

emitter.on('shell', message => {
    // iOS hooks
    if (global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cobiAuth &&
        global.webkit.messageHandlers.cobiAuth.postMessage) {
        return global.webkit.messageHandlers.cobiShell.postMessage(message)
    }

    // Android hooks
    if (global.cobijsAndroidImpl && global.cobijsAndroidImpl.messageFromJs) {
        return global.cobijsAndroidImpl.messageFromJs('cobiShell', JSON.stringify(message))
    }
})
