// @flow

/**
 * the action triggered by pressing on a thumb controller
 */
export type ExternalInterfaceAction = "UP" | "DOWN" | "LEFT" | "RIGHT" | "SELECT" | "HOME" | "ENTER_BACK" | "UP_POPOVER" | "DOWN_POPOVER" | "LEFT_POPOVER" | "RIGHT_POPOVER"
/**
 *
 */
export type BatteryState = "UNKNOWN" | "CHARGING" | "LOW_CHARGE" | "INTERMEDIATE_CHARGE" | "FULL_CHARGE"
/**
 *
 */
export type PlacemarkCategory = "NONE" | "FOOD" | "HEALTH" | "LEISURE" | "NIGHTLIFE" | "PUBLIC" | "SERVICE" | "SHOPPING" | "ACCOMODATION" | "TRANSPORT" | "BICYCLE_RELEVANT"
/**
 *
 */
export type NavigationAction = "STOP" | "PLAN" | "START"
/**
 *
 */
export type BikeType = "CITY" | "MTB" | "ROAD" | "URBAN" | "TREKKING"
/**
 *
 */
export type AmbientLightState = "DARK" | "TWILIGHT" | "BRIGHT"
/**
 *
 */
export type NavigationStatus = "NONE" | "CALCULATING" | "NAVIGATING"
/**
 *
 */
export type TemperatureUnit = "CELSIUS" | "FAHRENHEIT"
/**
 *
 */
export type MeasurementUnit = "METRIC" | "IMPERIAL"
/**
 *
 */
export type RgbColor = {
    red: number,
    green: number,
    blue: number
}
/**
 *
 */
export type Theme = {
    baseColor: RgbColor,
    identifier: string,
    bundleIdentifier: string,
    name: string,
    accentColor: RgbColor,
    backgroundColor: RgbColor,
    logoUrl: string
}
/**
 *
 */
export type BatteryCondition = {
    batteryLevel: number,
    state: BatteryState
}
/**
 * a latitude, longitude pair in decimal degrees format [+-]DDD.DDDDD
 */
export type Coordinate = {
    latitude: number,
    longitude: number
}
/**
 * the raw location information received from GPS
 */
export type Location = {
    coordinate: Coordinate,
    altitude: number,
    bearing: number,
    speed: number,
    horizontalAccuracy: number,
    verticalAccuracy: number
}
/**
 *
 */
export type Placemark = {
    name: string,
    address: string,
    category: PlacemarkCategory,
    coordinate: Coordinate
}
/**
 *
 */
export type Route = {
    origin: Placemark,
    destination: Placemark,
    name: string,
    distance: number,
    elevationGain: number,
    duration: number,
    predefined: boolean,
    waypoints: Coordinate
}
/**
 *
 */
export type NavigationCommand = {
    action: NavigationAction,
    destination: Coordinate
}
/**
 *
 */
export type ReadLaterItem = {
    title: string,
    url: string
}
/**
 *
 */
export type TextToSpeechContent = {
    language: string,
    content: string
}
/**
 *
 */
export type ContactData = {
    phone: string,
    email: string,
    url: string
}
/**
 * Motor service data information. Useful to inform the user of a maintenance service check for the bike
 */
export type ServiceTrigger = {
    timestamp: number,
    distance: number
}
