// @flow

import type {
    AmbientLightState,
    BatteryCondition,
    BikeType,
    ContactData,
    ExternalInterfaceAction,
    Location,
    MeasurementUnit,
    NavigationCommand,
    NavigationStatus,
    Placemark,
    ReadLaterItem,
    Route,
    ServiceTrigger,
    TemperatureUnit,
    TextToSpeechContent,
    Theme
} from './data.types'

const core = require('./core')
const action = require('./action')

const COBI = {
    specVersion: '0.44.0',
    // all elements prepended with `__` are for internal use only
    __emitter: core.emitter, // exposed here for backwards compatibility ... just in case
    __authentication: core.getAuthentication,

    /**
    * The Initialization function. You need to pass your apiKey from the COBI.Bike developer portal
    * in order to start receiving data
    * @name init
    * @example
    * COBI.init('token — can by any string right now')
    */
    init: (apiKey: string) => core.init(apiKey, COBI.specVersion),

    __authenticated: core.receiveAuthentication, // different name for backward compatibility

    __receiveMessage: core.receiveMessage,

    /** helper namespace for statical values which can be retrieved by
     * the COBI.Bike native app
     * @namespace parameters
     */
    parameters: {
        /** get the language specified by the user in the COBI.Bike app
         * @memberof parameters
         * @example
         * console.log(COBI.parameters.language())
         * // => 'en-US'
         */
        language: (): string => core.getUrlParameter('language') || 'en-US',

        /** get the current state that the webapp should reflect
         * @memberof parameters
         * @deprecated use {@link context} instead
         * @example
         * switch(COBI.parameters.state()) {
         *   case COBI.state.edit:
         *     // show something
         *   case COBI.state.overview:
         *     // show something else
         *   default:
         *     // ....
         * }
         */
        state: function (): string {
            console.warn('COBI.parameters.state() is deprecated !! ... please use COBI.parameters.context() instead')
            return core.getUrlParameter('state') || COBI.state.experience
        },

        /**
         *  get the current context that the module should reflect
         * @memberof parameters
         * @example
         * switch(COBI.parameters.context()) {
         *   case COBI.context.onRide:
         *     // show something
         *   case COBI.context.offRide:
         *     // show something else
         *   default:
         *     // ....
         * }
         */
        context: function (): string {
            let context = core.getUrlParameter('context')
            let state = core.getUrlParameter('state')

            if (context != null) {
                return context
            }

            if (state != null) {
                switch (state) {
                case COBI.state.experience:
                    return COBI.context.onRide
                case COBI.state.edit:
                    return COBI.context.offRideSettings
                }
            }
            // default
            return COBI.context.offRide
        },

        /** get the SDK version of the native app
         * @memberof parameters
         * @example console.log(COBI.parameters.nativeSdkVersion())
         * // => '0.34.1'
         */
        nativeSdkVersion: (): string => core.getUrlParameter('version') || COBI.specVersion
    },

    /**
     * The state definitions helps you manage the user experience on your module.
     * You should adapt your webapp content based on the state changes.
     * Whenever a WebApp is loaded it will receive the respective required Module State via URL
     * @namespace state
     * @deprecated use {@link context} instead
     * @example
     * switch(COBI.parameters.state()) {
     *   case COBI.state.edit:
     *     // show something
     *   case COBI.state.overview:
     *     // show something else
     *   default:
     *     // ....
     * }
     */
    state: {
        /**
         * The Module Experience State is the main content, i.e. experience of a WebApp. The user will spend
         * most of his time in this state of the WebApp during a ride
         * @memberof state
         */
        experience: 'experience',
        /**
         * The purpose of the Edit State of a WebApp Module is mainly to give the user instructions
         * on how to use the WebApp in form of a (standardised) Manual.
         * @memberof state
         */
        edit: 'edit',

        /**
         * The module overview state is when the webapp is opened without riding with a COBI.Bike Hub.
         * Therefore no thumb controller interaction nor bike values should be expected to flow to the
         * webapp
         * @memberof state
         */
        overview: 'overview'
    },

    /**
     * The context definitions helps you manage the user experience on your module.
     * You should adapt your webapp content based on the context changes.
     * The context parameter is passed through the URL parameters that a module receives on load
     * @namespace context
     * @example
     * switch(COBI.parameters.context()) {
     *   case COBI.context.onRide:
     *     // show something
     *   case COBI.context.offRide:
     *     // show something else
     *   default:
     *     // ....
     * }
     */
    context: {
        /**
         * Set during the riding phase. Show information relevant during the ride (e.g. live metrics) and offer
         * interaction that is optimized for the short attention time while riding.
         * @memberof context
         */
        onRide: 'onRide',

        /**
         * Set in the pre- and post-riding phase. Allow the user to prepare his next ride, give him an overview
         * of what he can expect during the riding phase or information about his last ride or offer services
         * that are not relevant during the riding phase (e.g. maintenance)
         * @memberof context
         */
        offRide: 'offRide',

        /**
         * Set when the user accesses the settings during the riding phase. Allow the user to quickly adjust
         * important settings while he is on the go. Those settings should be targeted to `COBI.context.onRide`
         * experience.
         * @memberof context
         */
        onRideSettings: 'onRideSettings',

        /**
         * Set when the user accesses the settings during the pre- and post-riding phase. Offer more in-depth
         * settings that are either targeted to `COBI.context.offRide` or both experiences
         * (depending on how your module is structured)
         * @memberof context
         */
        offRideSettings: 'offRideSettings'
    },

    /**
     * The app represents the main COBI.Bike user interface running on a mobile device. It can be used to get information about and to manipulate selected UI states and to request and send information to the user.
     * @namespace app
     */
    app: {
        /**
         * The app color theme. This can be used to match the appearance of the COBI.Bike app in, for example, DevKit modules.
         * @memberof app
         */
        theme: {
            /**
             * @memberof app.theme
             */
            subscribe: (callback: (value: Theme, timestamp?: Date)=> void) => action.subscribe('app/theme', callback),

            /**
             * @memberof app.theme
             */
            unsubscribe: (callback?: (value: Theme, timestamp?: Date)=> void) => action.unsubscribe('app/theme', callback)
        },
        /**
         * Let's the phone speak out the provided text using the built in text to speech engine. The language parameter is ignored for now and the system language is used.
         * @memberof app
         */
        textToSpeech: {
            /**
             * @memberof app.textToSpeech
             */
            write: (request: TextToSpeechContent, callback?: (response: TextToSpeechContent, timestamp?: Date)=> void) => action.write('app/textToSpeech', request, callback)
        },
        /**
         * Adds an entry to the web-browser read later list.
         * @memberof app
         */
        readLater: {
            /**
             * @memberof app.readLater
             */
            write: (request: ReadLaterItem, callback?: (response: ReadLaterItem, timestamp?: Date)=> void) => action.write('app/readLater', request, callback)
        },
        /**
         * The language the phone is set to. Used to localize all content presented to the user. Represented according to RFC 5646. Example: "en-US", "de-CH".
         * @memberof app
         */
        language: {
            /**
             * @memberof app.language
             */
            subscribe: (callback: (value: string, timestamp?: Date)=> void) => action.subscribe('app/language', callback),

            /**
             * @memberof app.language
             */
            unsubscribe: (callback?: (value: string, timestamp?: Date)=> void) => action.unsubscribe('app/language', callback)
        },
        /**
         * Prompts the user to pick a contact from the list of favorite contacts. The selected contact will be returned. Nothing is returned if the user cancelled the contact picker.
         * @memberof app
         */
        contact: {
            /**
             * @memberof app.contact
             */
            read: (callback?: (response: ContactData, timestamp?: Date)=> void) => action.read('app/contact', callback)
        },
        /**
         * If the user is allowed to interact with the user interface via touch. This status is based on the fact if he is currently riding or standing.

         When disabled, all touch-interactive elements should be removed from the screen and the amount of information should be reduced to avoid distracting the user while he/she is riding.

         When enabled, a navigation bar is shown at the top of the screen which reduces the vertical size available to modules.
         * @memberof app
         */
        touchInteractionEnabled: {
            /**
             * @memberof app.touchInteractionEnabled
             */
            subscribe: (callback: (value: boolean, timestamp?: Date)=> void) => action.subscribe('app/touchInteractionEnabled', callback),

            /**
             * @memberof app.touchInteractionEnabled
             */
            unsubscribe: (callback?: (value: boolean, timestamp?: Date)=> void) => action.unsubscribe('app/touchInteractionEnabled', callback)
        },
        /**
         * The last known hub location. The location is updated sporadically while the hub is connected. When it gets disconnected, the current location is stored. If you want to receive continous location updates, use mobile.location.
         * @memberof app
         */
        hubLocation: {
            /**
             * @memberof app.hubLocation
             */
            read: (callback?: (response: Placemark, timestamp?: Date)=> void) => action.read('app/hubLocation', callback),
            /**
             * @memberof app.hubLocation
             */
            subscribe: (callback: (value: Placemark, timestamp?: Date)=> void) => action.subscribe('app/hubLocation', callback),

            /**
             * @memberof app.hubLocation
             */
            unsubscribe: (callback?: (value: Placemark, timestamp?: Date)=> void) => action.unsubscribe('app/hubLocation', callback)
        },
        /**
         * If the clock overlay is shown on top of module experiences. By default, the clock is visible. When written immediately on module start, the clock is not shown at all.
         * @memberof app
         */
        clockVisible: {
            /**
             * @memberof app.clockVisible
             */
            write: (request: boolean, callback?: (response: boolean, timestamp?: Date)=> void) => action.write('app/clockVisible', request, callback)
        },
        /**
         * Reflects if it is dark outside. This is decided based on the time of the day. You may want to show a dark theme when it is dark to improve the user experience.
         * @memberof app
         */
        isDark: {
            /**
             * @memberof app.isDark
             */
            read: (callback?: (response: boolean, timestamp?: Date)=> void) => action.read('app/isDark', callback),
            /**
             * @memberof app.isDark
             */
            subscribe: (callback: (value: boolean, timestamp?: Date)=> void) => action.subscribe('app/isDark', callback),

            /**
             * @memberof app.isDark
             */
            unsubscribe: (callback?: (value: boolean, timestamp?: Date)=> void) => action.unsubscribe('app/isDark', callback)
        },
        /**
         * If the user's hub is connected to the app
         * @memberof app
         */
        isHubConnected: {
            /**
             * @memberof app.isHubConnected
             */
            read: (callback?: (response: boolean, timestamp?: Date)=> void) => action.read('app/isHubConnected', callback),
            /**
             * @memberof app.isHubConnected
             */
            subscribe: (callback: (value: boolean, timestamp?: Date)=> void) => action.subscribe('app/isHubConnected', callback),

            /**
             * @memberof app.isHubConnected
             */
            unsubscribe: (callback?: (value: boolean, timestamp?: Date)=> void) => action.unsubscribe('app/isHubConnected', callback)
        }
    },
    /**
     * The hub is the COBI.Bike device the smartphone is mounted on. When connected to the smartphone, it makes information about it's state and external input available and allows to trigger certain actions. Before usage, a hub has to be activated via the COBI.Bike API (done during the setup on the phone).
     * @namespace hub
     */
    hub: {
        /**
         * If the e-bike driver is available, up and running. When this is reported, you can be sure to be on an e-bike and can expect e-bike sepecific information (see motor).
         * @memberof hub
         */
        motorInterfaceReady: {
            /**
             * @memberof hub.motorInterfaceReady
             */
            read: (callback?: (response: boolean, timestamp?: Date)=> void) => action.read('hub/motorInterfaceReady', callback),
            /**
             * @memberof hub.motorInterfaceReady
             */
            subscribe: (callback: (value: boolean, timestamp?: Date)=> void) => action.subscribe('hub/motorInterfaceReady', callback),

            /**
             * @memberof hub.motorInterfaceReady
             */
            unsubscribe: (callback?: (value: boolean, timestamp?: Date)=> void) => action.unsubscribe('hub/motorInterfaceReady', callback)
        },
        /**
         * If the bell sound is curently played. When the user presses the bell button, it changes to true and back to false when it finished playing.
         * @memberof hub
         */
        bellRinging: {
            /**
             * @memberof hub.bellRinging
             */
            subscribe: (callback: (value: boolean, timestamp?: Date)=> void) => action.subscribe('hub/bellRinging', callback),

            /**
             * @memberof hub.bellRinging
             */
            unsubscribe: (callback?: (value: boolean, timestamp?: Date)=> void) => action.unsubscribe('hub/bellRinging', callback)
        },
        /**
         * Triggered when user presses a button on the connected thumb controller. Which action depends on the thumb controller mapping that is currently active (see devkit.overrideThumbControllerMapping).

         The actions are used to control the mobile app. Only the following actions are made available to DevKit modules: UP, DOWN, LEFT, RIGHT, SELECT.
         * @memberof hub
         */
        externalInterfaceAction: {
            /**
             * @memberof hub.externalInterfaceAction
             */
            subscribe: (callback: (value: ExternalInterfaceAction, timestamp?: Date)=> void) => action.subscribe('hub/externalInterfaceAction', callback),

            /**
             * @memberof hub.externalInterfaceAction
             */
            unsubscribe: (callback?: (value: ExternalInterfaceAction, timestamp?: Date)=> void) => action.unsubscribe('hub/externalInterfaceAction', callback)
        },
        /**
         * The current ambient light state. Based on the value measured by the hubs ambient light sensor, one of the categories is reported.
         * @memberof hub
         */
        ambientLightState: {
            /**
             * @memberof hub.ambientLightState
             */
            read: (callback?: (response: AmbientLightState, timestamp?: Date)=> void) => action.read('hub/ambientLightState', callback),
            /**
             * @memberof hub.ambientLightState
             */
            subscribe: (callback: (value: AmbientLightState, timestamp?: Date)=> void) => action.subscribe('hub/ambientLightState', callback),

            /**
             * @memberof hub.ambientLightState
             */
            unsubscribe: (callback?: (value: AmbientLightState, timestamp?: Date)=> void) => action.unsubscribe('hub/ambientLightState', callback)
        }
    },
    /**
     *
     * @namespace battery
     */
    battery: {
        /**
         * Represents the external battery condition (Hub or E-bike battery respectively). I.e. charging level in % and status.
         * @memberof battery
         */
        state: {
            /**
             * @memberof battery.state
             */
            read: (callback?: (response: BatteryCondition, timestamp?: Date)=> void) => action.read('battery/state', callback),
            /**
             * @memberof battery.state
             */
            subscribe: (callback: (value: BatteryCondition, timestamp?: Date)=> void) => action.subscribe('battery/state', callback),

            /**
             * @memberof battery.state
             */
            unsubscribe: (callback?: (value: BatteryCondition, timestamp?: Date)=> void) => action.unsubscribe('battery/state', callback)
        }
    },
    /**
     * The Motor Channel transmits data related to an eBike. The channel only transmits that data if the Hub is actually attached to an eBike. The Bus will not write or read any data until the *Ebike System Available* Property of the [Main Bus]((#message-bus-channels---main) is true.
     * @namespace motor
     */
    motor: {
        /**
         * Defines the total traveled distance by the system in km.
         * @memberof motor
         */
        distance: {
            /**
             * @memberof motor.distance
             */
            read: (callback?: (response: number, timestamp?: Date)=> void) => action.read('motor/distance', callback),
            /**
             * @memberof motor.distance
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('motor/distance', callback),

            /**
             * @memberof motor.distance
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('motor/distance', callback)
        },
        /**
         * Utilization level in % of the currently set drive mode engine support.
         * @memberof motor
         */
        assistanceIndicator: {
            /**
             * @memberof motor.assistanceIndicator
             */
            read: (callback?: (response: number, timestamp?: Date)=> void) => action.read('motor/assistanceIndicator', callback),
            /**
             * @memberof motor.assistanceIndicator
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('motor/assistanceIndicator', callback),

            /**
             * @memberof motor.assistanceIndicator
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('motor/assistanceIndicator', callback)
        },
        /**
         * Defines the range value in km of the eBike. That means which distance you can travel with eBike engine support.
         * @memberof motor
         */
        range: {
            /**
             * @memberof motor.range
             */
            read: (callback?: (response: number, timestamp?: Date)=> void) => action.read('motor/range', callback),
            /**
             * @memberof motor.range
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('motor/range', callback),

            /**
             * @memberof motor.range
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('motor/range', callback)
        },
        /**
         * Defines the number of supported drive modes by the eBike. I.e. 5.
         * @memberof motor
         */
        supportedDriveModes: {
            /**
             * @memberof motor.supportedDriveModes
             */
            read: (callback?: (response: number, timestamp?: Date)=> void) => action.read('motor/supportedDriveModes', callback),
            /**
             * @memberof motor.supportedDriveModes
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('motor/supportedDriveModes', callback),

            /**
             * @memberof motor.supportedDriveModes
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('motor/supportedDriveModes', callback)
        },
        /**
         * Defines the eBike drive mode of the specific vendor protocol. In ascending order. Depending on the Supported Drive Modes
         * @memberof motor
         */
        driveMode: {
            /**
             * @memberof motor.driveMode
             */
            read: (callback?: (response: number, timestamp?: Date)=> void) => action.read('motor/driveMode', callback),
            /**
             * @memberof motor.driveMode
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('motor/driveMode', callback),

            /**
             * @memberof motor.driveMode
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('motor/driveMode', callback)
        },
        /**
         * Provides information about the maintenance status of the bike; at which point in time and/or distance it is needed
         * @memberof motor
         */
        nextService: {
            /**
             * @memberof motor.nextService
             */
            read: (callback?: (response: ServiceTrigger, timestamp?: Date)=> void) => action.read('motor/nextService', callback),
            /**
             * @memberof motor.nextService
             */
            subscribe: (callback: (value: ServiceTrigger, timestamp?: Date)=> void) => action.subscribe('motor/nextService', callback),

            /**
             * @memberof motor.nextService
             */
            unsubscribe: (callback?: (value: ServiceTrigger, timestamp?: Date)=> void) => action.unsubscribe('motor/nextService', callback)
        }
    },
    /**
     * Channel for reporting hardware-related values
     * @namespace mobile
     */
    mobile: {
        /**
         * A data class representing a geographic location. A location can consist of a latitude, longitude, timestamp and other information such as bearing, altitude and velocity.
         * @memberof mobile
         */
        location: {
            /**
             * @memberof mobile.location
             */
            read: (callback?: (response: Location, timestamp?: Date)=> void) => action.read('mobile/location', callback),
            /**
             * @memberof mobile.location
             */
            subscribe: (callback: (value: Location, timestamp?: Date)=> void) => action.subscribe('mobile/location', callback),

            /**
             * @memberof mobile.location
             */
            unsubscribe: (callback?: (value: Location, timestamp?: Date)=> void) => action.unsubscribe('mobile/location', callback)
        },
        /**
         * Represents the direction the device is hold in degrees, where 0 degrees is true North
         * @memberof mobile
         */
        heading: {
            /**
             * @memberof mobile.heading
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('mobile/heading', callback),

            /**
             * @memberof mobile.heading
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('mobile/heading', callback)
        },
        /**
         * If the location and the heading are available (depending on the device capabilities and the user confirmation)
         * @memberof mobile
         */
        locationAvailability: {
            /**
             * @memberof mobile.locationAvailability
             */
            subscribe: (callback: (value: boolean, timestamp?: Date)=> void) => action.subscribe('mobile/locationAvailability', callback),

            /**
             * @memberof mobile.locationAvailability
             */
            unsubscribe: (callback?: (value: boolean, timestamp?: Date)=> void) => action.unsubscribe('mobile/locationAvailability', callback)
        }
    },
    /**
     * Refers to all navigation related information calculated or provided by the navigation engine on the COBI.Bike app
     * @namespace navigationService
     */
    navigationService: {
        /**
         * The calculated navigation route. Drawn on the map during a ride and used for turn-by-turn advices. The route is not updated while riding — live information is available via the navigation service eta and distance to destination. To request a route to a different destination write to the navigation service control.
         * @memberof navigationService
         */
        route: {
            /**
             * @memberof navigationService.route
             */
            subscribe: (callback: (value: Route, timestamp?: Date)=> void) => action.subscribe('navigationService/route', callback),

            /**
             * @memberof navigationService.route
             */
            unsubscribe: (callback?: (value: Route, timestamp?: Date)=> void) => action.unsubscribe('navigationService/route', callback)
        },
        /**
         * Estimated time of arrival as timestamp: seconds since 00:00:00 UTC on 1 January 1970
         * @memberof navigationService
         */
        eta: {
            /**
             * @memberof navigationService.eta
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('navigationService/eta', callback),

            /**
             * @memberof navigationService.eta
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('navigationService/eta', callback)
        },
        /**
         * Distance remaining until the set destination in meters
         * @memberof navigationService
         */
        distanceToDestination: {
            /**
             * @memberof navigationService.distanceToDestination
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('navigationService/distanceToDestination', callback),

            /**
             * @memberof navigationService.distanceToDestination
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('navigationService/distanceToDestination', callback)
        },
        /**
         * Defines the current status of the navigation engine
         * @memberof navigationService
         */
        status: {
            /**
             * @memberof navigationService.status
             */
            subscribe: (callback: (value: NavigationStatus, timestamp?: Date)=> void) => action.subscribe('navigationService/status', callback),

            /**
             * @memberof navigationService.status
             */
            unsubscribe: (callback?: (value: NavigationStatus, timestamp?: Date)=> void) => action.unsubscribe('navigationService/status', callback)
        },
        /**
         * Controls the navigation service route and status. When triggering `plan` or `start` a new route is calculated. When the calculation is finished, the new route is published as the navigation service route.
         * @memberof navigationService
         */
        control: {
            /**
             * @memberof navigationService.control
             */
            write: (request: NavigationCommand, callback?: (response: NavigationCommand, timestamp?: Date)=> void) => action.write('navigationService/control', request, callback)
        }
    },
    /**
     * Channel for reporting user-related information setup in the COBI.Bike app
     * @namespace user
     */
    user: {
        /**
         *
         * @memberof user
         */
        temperatureUnit: {
            /**
             * @memberof user.temperatureUnit
             */
            subscribe: (callback: (value: TemperatureUnit, timestamp?: Date)=> void) => action.subscribe('user/temperatureUnit', callback),

            /**
             * @memberof user.temperatureUnit
             */
            unsubscribe: (callback?: (value: TemperatureUnit, timestamp?: Date)=> void) => action.unsubscribe('user/temperatureUnit', callback)
        },
        /**
         *
         * @memberof user
         */
        lengthUnit: {
            /**
             * @memberof user.lengthUnit
             */
            subscribe: (callback: (value: MeasurementUnit, timestamp?: Date)=> void) => action.subscribe('user/lengthUnit', callback),

            /**
             * @memberof user.lengthUnit
             */
            unsubscribe: (callback?: (value: MeasurementUnit, timestamp?: Date)=> void) => action.unsubscribe('user/lengthUnit', callback)
        }
    },
    /**
     * Channel for reporting bike-related configuration parameters
     * @namespace bike
     */
    bike: {
        /**
         * The type of bike you are using
         * @memberof bike
         */
        type: {
            /**
             * @memberof bike.type
             */
            read: (callback?: (response: BikeType, timestamp?: Date)=> void) => action.read('bike/type', callback),
            /**
             * @memberof bike.type
             */
            subscribe: (callback: (value: BikeType, timestamp?: Date)=> void) => action.subscribe('bike/type', callback),

            /**
             * @memberof bike.type
             */
            unsubscribe: (callback?: (value: BikeType, timestamp?: Date)=> void) => action.unsubscribe('bike/type', callback)
        }
    },
    /**
     * Channel for reporting information calculated and provided by the intelligence library in the COBI.Bike app [More information](https://github.com/cobi-bike/COBI-Intelligence)
     * @namespace rideService
     */
    rideService: {
        /**
         * Speed provided by the intelligence library used in the app (in m/s)
         * @memberof rideService
         */
        speed: {
            /**
             * @memberof rideService.speed
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('rideService/speed', callback),

            /**
             * @memberof rideService.speed
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('rideService/speed', callback)
        },
        /**
         * The expressed power from the user. A heart-rate sensor is the primary source for calculating this, but if it is missing - current bike's speed, road grade, etc. are used. Also the biker's body-metrics are always taken into account. The value is in Watts
         * @memberof rideService
         */
        userPower: {
            /**
             * @memberof rideService.userPower
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('rideService/userPower', callback),

            /**
             * @memberof rideService.userPower
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('rideService/userPower', callback)
        },
        /**
         * If the user power is available from any sensor
         * @memberof rideService
         */
        userPowerAvailability: {
            /**
             * @memberof rideService.userPowerAvailability
             */
            subscribe: (callback: (value: boolean, timestamp?: Date)=> void) => action.subscribe('rideService/userPowerAvailability', callback),

            /**
             * @memberof rideService.userPowerAvailability
             */
            unsubscribe: (callback?: (value: boolean, timestamp?: Date)=> void) => action.unsubscribe('rideService/userPowerAvailability', callback)
        },
        /**
         * Heart Rate  — in bpm. Unifies all the sources for heart rate. When heart rate is reported from different sensors, only the most accurate one is reported on this property.
         * @memberof rideService
         */
        heartRate: {
            /**
             * @memberof rideService.heartRate
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('rideService/heartRate', callback),

            /**
             * @memberof rideService.heartRate
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('rideService/heartRate', callback)
        },
        /**
         * If the heart rate is available from any sensor
         * @memberof rideService
         */
        heartRateAvailability: {
            /**
             * @memberof rideService.heartRateAvailability
             */
            subscribe: (callback: (value: boolean, timestamp?: Date)=> void) => action.subscribe('rideService/heartRateAvailability', callback),

            /**
             * @memberof rideService.heartRateAvailability
             */
            unsubscribe: (callback?: (value: boolean, timestamp?: Date)=> void) => action.unsubscribe('rideService/heartRateAvailability', callback)
        },
        /**
         * Cadence — in rpm. Unifies all the sources for cadence. When cadence is reported from different sensors, only the most accurate one is reported on this property.
         * @memberof rideService
         */
        cadence: {
            /**
             * @memberof rideService.cadence
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('rideService/cadence', callback),

            /**
             * @memberof rideService.cadence
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('rideService/cadence', callback)
        },
        /**
         * If the cadence is available from any sensor
         * @memberof rideService
         */
        cadenceAvailability: {
            /**
             * @memberof rideService.cadenceAvailability
             */
            subscribe: (callback: (value: boolean, timestamp?: Date)=> void) => action.subscribe('rideService/cadenceAvailability', callback),

            /**
             * @memberof rideService.cadenceAvailability
             */
            unsubscribe: (callback?: (value: boolean, timestamp?: Date)=> void) => action.unsubscribe('rideService/cadenceAvailability', callback)
        }
    },
    /**
     * Channel that describes a bicycle tour as a whole. It contains all statistical data that is available for a tour as well as their status. When a tour is ongoing, the statistical values are updated based on the live values provided by the ride_channel.

     [concept](https://drive.google.com/drive/folders/0B7qZU5q_H2aVcG14QThsLUNwMW8)
     * @namespace tourService
     */
    tourService: {
        /**
         * Total amount of calories burnt - in kcal.
         * @memberof tourService
         */
        calories: {
            /**
             * @memberof tourService.calories
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('tourService/calories', callback),

            /**
             * @memberof tourService.calories
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('tourService/calories', callback)
        },
        /**
         * The total aggregated ascend - in meters.
         * @memberof tourService
         */
        ascent: {
            /**
             * @memberof tourService.ascent
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('tourService/ascent', callback),

            /**
             * @memberof tourService.ascent
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('tourService/ascent', callback)
        },
        /**
         * The total distance travelled while moving - in meters.
         * @memberof tourService
         */
        ridingDistance: {
            /**
             * @memberof tourService.ridingDistance
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('tourService/ridingDistance', callback),

            /**
             * @memberof tourService.ridingDistance
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('tourService/ridingDistance', callback)
        },
        /**
         * The total movement duration — in seconds.
         * @memberof tourService
         */
        ridingDuration: {
            /**
             * @memberof tourService.ridingDuration
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('tourService/ridingDuration', callback),

            /**
             * @memberof tourService.ridingDuration
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('tourService/ridingDuration', callback)
        },
        /**
         * The average speed — in meters / second.
         * @memberof tourService
         */
        averageSpeed: {
            /**
             * @memberof tourService.averageSpeed
             */
            subscribe: (callback: (value: number, timestamp?: Date)=> void) => action.subscribe('tourService/averageSpeed', callback),

            /**
             * @memberof tourService.averageSpeed
             */
            unsubscribe: (callback?: (value: number, timestamp?: Date)=> void) => action.unsubscribe('tourService/averageSpeed', callback)
        }
    },
    /**
     * Used by the COBI.js library for developing COBI.Bike modules, the first smart connected biking system.
     * @namespace devkit
     */
    devkit: {
        /**
         * Quits the webapp and returns to the app selector
         * @memberof devkit
         */
        close: {
            /**
             * @memberof devkit.close
             */
            write: (request: boolean, callback?: (response: boolean, timestamp?: Date)=> void) => action.write('devkit/close', request, callback)
        },
        /**
         * Useful whenever a webapp wants to override the current thumb controller mapping and instead use all thumb controller action
         * @memberof devkit
         */
        overrideThumbControllerMapping: {
            /**
             * @memberof devkit.overrideThumbControllerMapping
             */
            write: (request: boolean, callback?: (response: boolean, timestamp?: Date)=> void) => action.write('devkit/overrideThumbControllerMapping', request, callback)
        }
    }
}

module.exports = COBI
