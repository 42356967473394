// @flow

export type Action = 'READ' | 'WRITE' | 'NOTIFY'

export type PropertyPath =
    'app/theme' |
    'app/textToSpeech' |
    'app/readLater' |
    'app/language' |
    'app/contact' |
    'app/touchInteractionEnabled' |
    'app/hubLocation' |
    'app/clockVisible' |
    'app/isDark' |
    'app/isHubConnected' |
    'hub/motorInterfaceReady' |
    'hub/bellRinging' |
    'hub/externalInterfaceAction' |
    'hub/ambientLightState' |
    'battery/state' |
    'motor/distance' |
    'motor/assistanceIndicator' |
    'motor/range' |
    'motor/supportedDriveModes' |
    'motor/driveMode' |
    'motor/nextService' |
    'mobile/location' |
    'mobile/heading' |
    'mobile/locationAvailability' |
    'navigationService/route' |
    'navigationService/eta' |
    'navigationService/distanceToDestination' |
    'navigationService/status' |
    'navigationService/control' |
    'user/temperatureUnit' |
    'user/lengthUnit' |
    'bike/type' |
    'rideService/speed' |
    'rideService/userPower' |
    'rideService/userPowerAvailability' |
    'rideService/heartRate' |
    'rideService/heartRateAvailability' |
    'rideService/cadence' |
    'rideService/cadenceAvailability' |
    'tourService/calories' |
    'tourService/ascent' |
    'tourService/ridingDistance' |
    'tourService/ridingDuration' |
    'tourService/averageSpeed' |
    'devkit/close' |
    'devkit/overrideThumbControllerMapping'

export type Message = {
    action: Action,
    path: PropertyPath,
    timestamp?: number,
    payload?: any
}
